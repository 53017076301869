import { createTheme } from '@mui/material/styles';

export const theme = createTheme({
	palette: {
		primary: {
			main: '#3c0f98',
		},
		secondary: {
			main: '#b1f005',
			dark: '#9ad200',
		},
		text: {
			primary: '#180b32',
			secondary: 'rgba(0,0,0,0.8)',
		},
	},
	typography: {
		button: {
			fontSize: '1rem',
			textTransform: 'none',
			letterSpacing: '0.01em',
		},
		h1: {
			fontSize: '2rem',
			fontWeight: 500,
			letterSpacing: '-0.01em',
		},
		h2: {
			fontSize: '3rem',
			fontWeight: 500,
			letterSpacing: '0.01em',
		},
		h3: {
			fontSize: '2.5rem',
			fontWeight: 700,
			letterSpacing: '-0.02em',
		},
		h4: {
			fontSize: '2rem',
			fontWeight: 700,
		},
		h5: {
			fontSize: '1.75rem',
			fontWeight: 500,
		},
		h6: {
			fontSize: '1.5rem',
		},
		subtitle1: {
			fontSize: '1.25rem',
		},
	},
});
