import React, { useState, useRef } from 'react';
import moment from 'moment';

import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { TextField } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import 'moment/locale/de';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import BoltIcon from '@mui/icons-material/Bolt';
import LocalFireDepartmentIcon from '@mui/icons-material/LocalFireDepartment';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';

const App = () => {
	const [date, setDate] = useState(moment());
	const [meter, setMeter] = useState(1);
	const [notAllData, setNotAllData] = useState(false);
	const [success, setSuccess] = useState(false);
	const [error, setError] = useState(false);
	const [disabled, setDisabled] = useState(false);
	const [energyKat, setEnergsKat] = useState(1);

	const refName = useRef('');
	const refKnr = useRef('');
	const refZnr = useRef('');
	const refZstSingle = useRef('');
	const refZstNt = useRef('');
	const refZstHt = useRef('');

	const handleChange = (newValue) => {
		setDate(newValue);
	};

	const handleChangeMeter = (event: SelectChangeEvent) => {
		setMeter(event.target.value);
	};

	const handleEnergyKat = (event, newAlignment) => {
		setNotAllData(false);
		setSuccess(false);
		setEnergsKat(newAlignment);
		if (newAlignment === null) {
			setEnergsKat(1);
		}
	};

	const validateZst = (zst) => {
		setDisabled(false);
		setError(false);
		if (zst.indexOf(',') > -1) {
			setDisabled(true);
			setError(true);
		}
	};

	const onSubmit = () => {
		if (meter === 1) {
			if (refZstSingle.current.value.indexOf('-') > -1) {
				refZstSingle.current.value = refZstSingle.current.value.replace(/-/g, '');
			}
		}
		if (meter === 2) {
			if (refZstHt.current.value.indexOf('-') > -1) {
				refZstHt.current.value = refZstHt.current.value.replace(/-/g, '');
			}
			if (refZstNt.current.value.indexOf('-') > -1) {
				refZstNt.current.value = refZstNt.current.value.replace(/-/g, '');
			}
		}

		if (
			refKnr.current.value !== '' &&
			refName.current.value !== '' &&
			refZnr.current.value !== '' &&
			(meter === 1
				? refZstSingle.current !== null
					? refZstSingle.current.value !== ''
					: ''
				: refZstHt.current.value !== '' && refZstNt.current.value !== '')
		) {
			setSuccess(false);
			setNotAllData(false);
			//setDisabled(true);
			fetch('https://dev01.firstcon.de/api/v2/MeterReading', {
				method: 'POST',
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({
					CustomerNumber: refKnr.current.value,
					CustomerName: refName.current.value,
					MeterNumber: refZnr.current.value,
					DateOfMeterReading: date,
					MeterReadingFirstValue: meter === 1 ? refZstSingle.current.value : refZstHt.current.value,
					MeterReadingSecondValue: meter === 1 ? 0 : refZstNt.current.value,
					ContractTypeId: energyKat,
					MeterTypeId: 0,
				}),
			})
				.then((response) => response.json())
				.then((json) => {
					if (json.MeterNumber) {
						//setDisabled(true);
						setSuccess(true);
					}
				})
				.catch((error) => {
					console.log(error);
				});
		} else {
			setNotAllData(true);
		}
	};

	return (
		<>
			<Grid container direction="row" justifyContent="center" alignItems="center" columns={12} mb={1}>
				<Grid item xs={12} lg={6}>
					<Typography variant="h1" sx={{ textAlign: 'left', marginBottom: '20px' }}>
						Zählerstandserfassung
					</Typography>
					<Typography variant="body2" color="text.secondary" sx={{ marginBottom: '10px' }}>
						Melden Sie Ihre Zählerstände für Strom und Gas einfach und bequem online. Bitte füllen Sie
						möglichst alle Felder aus.
					</Typography>
					<Typography variant="body2" color="text.secondary" sx={{ marginBottom: '15px' }}>
						Dies hilft uns, Ihren Zählerstand schnellstmöglich bearbeiten zu können. Vielen Dank.
					</Typography>
					<Box
						sx={{
							borderRadius: 4,
						}}
					>
						<ToggleButtonGroup value={energyKat} exclusive color={'primary'} onChange={handleEnergyKat}>
							<ToggleButton value={1} sx={{ minWidth: '100px' }}>
								<BoltIcon />
								Strom
							</ToggleButton>
							<ToggleButton value={2} sx={{ minWidth: '100px' }}>
								<LocalFireDepartmentIcon />
								Gas
							</ToggleButton>
						</ToggleButtonGroup>
						<Grid item xs={12}>
							<Card variant="outlined">
								{energyKat === 1 ? (
									<CardContent>
										<Grid
											container
											columns={12}
											mb={2}
											spacing={2}
											direction="row"
											alignItems="center"
										>
											<Grid item xs={12} sm={6}>
												<TextField
													id="name"
													inputRef={refName}
													label="Name"
													variant="outlined"
													fullWidth
												/>
											</Grid>
											<Grid item xs={12} sm={6}>
												<TextField
													id="knr"
													inputRef={refKnr}
													label="Kundennummer"
													variant="outlined"
													fullWidth
													placeholder="z.B. 0001458"
												/>
											</Grid>
										</Grid>
										<Grid container columns={12} mb={2} spacing={2}>
											<Grid item xs={12} sm={6}>
												<TextField
													id="znr"
													inputRef={refZnr}
													label="Zählernummer"
													variant="outlined"
													fullWidth
												/>
											</Grid>
											<Grid item xs={12} sm={6}>
												<LocalizationProvider adapterLocale={'de'} dateAdapter={AdapterMoment}>
													<DesktopDatePicker
														label="Ablesedatum"
														inputFormat="DD.MM.YYYY"
														value={date}
														onChange={handleChange}
														fullWidth
														maxDate={moment()}
														renderInput={(params) => <TextField {...params} fullWidth />}
													/>
												</LocalizationProvider>
											</Grid>
										</Grid>
										<Grid container columns={12} spacing={2}>
											<Grid item xs={12} sm={6}>
												<FormControl fullWidth>
													<InputLabel id="demo-simple-select-label">Zählertyp</InputLabel>
													<Select
														labelId="zaehlertyp"
														id="zaehlertyp"
														value={meter}
														label="Zähler"
														onChange={handleChangeMeter}
													>
														<MenuItem value={1}>Standard-Zähler / Eintarif-Zähler</MenuItem>
														<MenuItem value={2}>HT-NT-Zähler / Doppeltarif-Zähler</MenuItem>
													</Select>
												</FormControl>
											</Grid>
											{meter === 1 ? (
												<Grid item xs={12} sm={6}>
													<TextField
														id="zstand"
														type="number"
														inputRef={refZstSingle}
														label="Zählerstand: (Bitte ohne Kommastellen)"
														variant="outlined"
														fullWidth
														onChange={() => validateZst(refZstSingle.current.value)}
														error={error}
													/>
												</Grid>
											) : (
												<>
													<Grid item xs={12} sm={6}>
														<TextField
															id="zstandht"
															inputProps={{ min: 0 }}
															inputRef={refZstHt}
															type="number"
															label="Zählerstand HT: (Bitte ohne Kommastellen)"
															variant="outlined"
															fullWidth
															onChange={() => validateZst(refZstHt.current.value)}
															error={error}
														/>
													</Grid>
													<Grid item xs={12} sm={6}>
														<TextField
															id="zstandnt"
															inputProps={{ min: 0 }}
															inputRef={refZstNt}
															type="number"
															label="Zählerstand NT: (Bitte ohne Kommastellen)"
															variant="outlined"
															fullWidth
															onChange={() => validateZst(refZstNt.current.value)}
															error={error}
														/>
													</Grid>
												</>
											)}
										</Grid>
									</CardContent>
								) : (
									<CardContent>
										<Grid container direction="row" alignItems="center">
											<Grid container columns={12} mb={2} spacing={2}>
												<Grid item xs={12} sm={6}>
													<TextField
														id="name"
														inputRef={refName}
														label="Name"
														variant="outlined"
														fullWidth
													/>
												</Grid>
												<Grid item xs={12} sm={6}>
													<TextField
														id="knr"
														inputRef={refKnr}
														label="Kundennummer"
														variant="outlined"
														fullWidth
														placeholder="z.B. 0001458"
													/>
												</Grid>
											</Grid>
											<Grid container columns={12} spacing={2}>
												<Grid item xs={12} sm={6}>
													<TextField
														id="znr"
														inputRef={refZnr}
														label="Zählernummer"
														variant="outlined"
														fullWidth
													/>
												</Grid>
												<Grid item xs={12} sm={6}>
													<LocalizationProvider dateAdapter={AdapterMoment}>
														<DesktopDatePicker
															label="Ablesedatum"
															inputFormat="DD.MM.YYYY"
															value={date}
															onChange={handleChange}
															fullWidth
															renderInput={(params) => (
																<TextField {...params} fullWidth />
															)}
														/>
													</LocalizationProvider>
												</Grid>
												<Grid item xs={12} sm={6}>
													<TextField
														id="zstand"
														inputProps={{ min: 0 }}
														type="number"
														inputRef={refZstSingle}
														label="Zählerstand in m3: (Bitte ohne Kommastellen)"
														variant="outlined"
														fullWidth
														onChange={() => validateZst(refZstSingle.current.value)}
														error={error}
													/>
												</Grid>
											</Grid>
										</Grid>
									</CardContent>
								)}

								<Divider />
								<Typography variant="body2" color="text.secondary" sx={{ margin: '15px' }}>
									Die Erfassung und weitere Bearbeitung Ihrer Anfrage erfolgt durch die Firstcon GmbH
									, Untere Schrangenstraße 4, 21335 Lüneburg,{' '}
									<Link target={'_blank'} href="https://www.firstcon.de/" variant="body2">
										https://www.firstcon.de
									</Link>
									. Mit dem Absenden des Formulars erklären Sie sich damit einverstanden, dass Ihre
									Daten zur Bearbeitung Ihres Anliegens verwendet werden. Weitere Informationen und
									Widerrufshinweise finden Sie in der{' '}
									<Link target={'_blank'} href="https://firstcon.de/datenschutz.html" variant="body2">
										Datenschutzerklärung
									</Link>
									.
								</Typography>
								{notAllData ? (
									<Alert sx={{ margin: '15px' }} variant="filled" severity="warning">
										<AlertTitle>Fehler</AlertTitle>
										Bitte alle erforderlichen Felder ausfüllen!
									</Alert>
								) : (
									''
								)}
								{success ? (
									<Alert sx={{ margin: '15px' }} variant="filled" severity="success">
										Zählerstand erfolgreich übertragen!
									</Alert>
								) : (
									''
								)}
								<CardActions sx={{ justifyContent: 'flex-start' }}>
									<Button
										disabled={disabled}
										onClick={onSubmit}
										variant="contained"
										endIcon={<ArrowForwardIcon />}
									>
										Zählerstand senden
									</Button>
								</CardActions>
							</Card>
						</Grid>
					</Box>
				</Grid>
			</Grid>
		</>
	);
};

export default App;
